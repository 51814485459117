import * as firebase from "firebase/app";
import "firebase/database";
import firebaseConfig from "./config";

firebase.initializeApp(firebaseConfig);

const getMessageLogs = _updateLogs => {
  let logsRef = firebase.database().ref("/migration_log");
  logsRef.limitToLast(500).on("child_added", childSnapshot => {
    _updateLogs(childSnapshot.val());
  });
};

export default getMessageLogs;
