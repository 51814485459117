import moment from 'moment';
import React, { useState, useEffect } from 'react';

import './App.css';
import getMessageLogs from './firebase/firebase';

function formattedDate(date) {
  return moment(date).format('MMMM Do YYYY, h:mm:ss a');
}
function App() {
  let [migrationLogs, setMigrationLogs] = useState([]);

  useEffect(() => {
    getMessageLogs(_updateLogs);
    //eslint-disable-next-line
  }, []);

  const _updateLogs = value => {
    migrationLogs.unshift(value);
    setMigrationLogs([...migrationLogs]);
  };

  return (
    <div className="App">
      <h1> Migration Logs:</h1>
      <table style={{ width: '100%' }}>
        <tbody>
          {migrationLogs.map((log, i) => {
            return (
              <tr
                style={{
                  background: i % 2 === 0 ? '#d8cdcd' : 'white',
                  color: i % 2 === 0 ? 'black' : 'black',
                  margin: 10,
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <td style={{ flexGrow: '1' }}> {formattedDate(log.time)}</td>
                <td style={{ flexGrow: '3', textAlign: 'left' }}>
                  {' '}
                  {log.message}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default App;
